/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/css/assets/custom.scss"

const $ = require("jquery")

export const onInitialClientRender = () => {
  $(document).ready(function () {
     if(window.location.href == "https://sapphirebeachbelize.com/listings/" || window.location.href == "https://sapphirebeachbelize.com/listings" || window.location.href == "https://sapphirebeachbelize.com/listings/item/"){
        window.location.href = "https://azulmanagement.co/realestate/belize/sapphire-beach-resort-island-real-estate-villas-ambergris-caye-belize"
     }
  });
}
 